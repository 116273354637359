// @mui
import { useEffect, useState } from 'react';

import { Stack, Button, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import CircularProgress from '@mui/material/CircularProgress';
import useGetProgress from '../../../hooks/useGetProgress';

// routes
import { PATH_DOCS } from '../../../routes/paths';

import ClaimModal from '../../../components/ClaimModal';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useMoralis();

  const { data: profile } = useMoralisQuery('NPPublicUsers', (q) =>
    q.equalTo('wallet', user?.attributes?.ethAddress, [user?.attributes?.ethAddress])
  );
  const { data: achievements } = useMoralisQuery('Achievements', (q) => q);

  const { fetchingProgress, handleProgress } = useGetProgress(profile[0], achievements);

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ px: 5, pb: 5, width: 1, textAlign: 'center', display: 'block' }}>
      {profile ? (
        <div style={{ height: '5vh' }}>
          <ClaimModal isOpen={isOpen} handleClose={handleClose} handleOpen={handleOpen} profile={profile[0]} />

          <Button sx={{ width: '100%' }} onClick={() => handleOpen()} variant="contained">
            Claim Points
          </Button>
        </div>
      ) : null}

      <Typography style={{ color: 'gray' }} variant="body-1">
        Version 3.0.6
      </Typography>
    </Stack>
  );
}

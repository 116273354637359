import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname,  } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'register',
                    element: (
                        <AuthGuard>
                            <Register />
                        </AuthGuard>
                    ),
                },
                { path: 'register-unprotected', element: <Register /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'verify', element: <VerifyCode /> },
            ],
        },
        {
            path: '/connect',
            element:
                <GuestGuard>
                    <Connect />
                </GuestGuard>
        },

        // Dashboard Routes
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'analytics', element: <GeneralAnalytics /> },
                {
                    path: 'user',
                    children: [
                        { element: <Navigate to="/dashboard/user/profile" replace />, index: true },

                        { path: 'cards', element: <UserCards /> },
                        { path: 'list', element: <UserList /> },
                        { path: 'new', element: <UserCreate /> },
                        { path: ':name/edit', element: <UserCreate /> },
                        { path: 'account', element: <UserAccount /> },
                    ],
                },

            ],
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'coming-soon', element: <ComingSoon /> },
                { path: 'maintenance', element: <Maintenance /> },
                { path: 'denied', element: <Denied /> },
                { path: 'pricing', element: <Pricing /> },
                { path: 'payment', element: <Payment /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        {
            path: '/',
            element: <DashboardLayout />,
            // element: <Navigate to="/connect" replace />,
            children: [
                {
                    element:
                        <AuthGuard>
                            <Navigate to="/mynfts" replace />
                        </AuthGuard>,
                    index: true
                },
                {
                    path: 'mynfts',
                    element:
                        <AuthGuard>
                            <MyNFTs />
                        </AuthGuard>
                },
                {
                    path: 'leaderboard',
                    element:
                        <AuthGuard>
                            <LeaderBoard />
                        </AuthGuard>
                },
                {
                    path: 'leaderboard/:id/:username/:currentPage',
                    element:
                        <AuthGuard>
                            <Owner />
                        </AuthGuard>
                },
                {
                    path: 'claim/:id',
                    element:
                        <AuthGuard>
                            <Claim />
                        </AuthGuard>
                },
                {
                    path: 'points',
                    element:
                        <AuthGuard>
                            <Points />
                        </AuthGuard>
                },
                {
                    path: 'mypoints',
                    element:
                        <AuthGuard>
                            <MyPoints />
                        </AuthGuard>
                },
                {
                    path: 'claim',
                    element:
                        <AuthGuard>
                            <Claim />
                        </AuthGuard>
                },
                {
                    path: 'perks',
                    element:
                        <AuthGuard>
                            <Perks />
                        </AuthGuard>
                },
            ],
        },

        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// connect
const Connect = Loadable(lazy(() => import('../pages/Connect')));

const MyNFTs = Loadable(lazy(() => import('../pages/MyNFTs')));
const LeaderBoard = Loadable(lazy(() => import('../pages/LeaderBoard')));
const Owner = Loadable(lazy(() => import('../pages/MyNFTs')));
const Points = Loadable(lazy(() => import('../pages/Points')))
const MyPoints = Loadable(lazy(() => import('../pages/MyPoints')))
const Claim = Loadable(lazy(() => import('../pages/Claim')))

// IMPORT COMPONENTS

// Authentication

const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard

const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));


const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// Main
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Denied = Loadable(lazy(() => import('../pages/Denied')));
const Perks = Loadable(lazy(() => import('../pages/Perks')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

export async function handleAchievement(Moralis, level, id) {
    const Achievements = Moralis.Object.extend('EarnedAchievements')
    const query = new Moralis.Query(Achievements)

    query.equalTo("profile", id)
    query.equalTo("achievementId", level?.id)

    const results = await query.find()

    if (results?.length === 0) {

        const achievement = new Achievements()

        achievement.set("profile", id)
        achievement.set("achievementId", level?.id)
        achievement.set("name", level?.attributes?.rewardTitle)
        achievement.set("description", level?.attributes?.rewardDescription)
        achievement.set("achievementImage", level?.attributes?.achievementImage)
        achievement.set("points", Number(level?.attributes?.rewardPoints))
        achievement.set("category", level?.attributes?.rewardType)
        achievement.set("claimed", false)

        await achievement.save()

        console.log('achievement has been created')

    } else {
        console.log('already has achievement')
    }

}

export async function getTraits(nfts, Moralis) {
    const Monster = Moralis.Object.extend("NationalParksData");
    const query = new Moralis.Query(Monster);

    const tokens = []

    for (let i = 0; i < nfts?.length; i += 1) {
        tokens.push(nfts[i]?.token_id)
    }

    query.containedIn("token_id", tokens);

    const results = await query.find()
    const parks = []

    results?.forEach((item) => {
        const park = item?.attributes?.attributes?.filter((item) => item.trait_type === 'Parks')
        if (!parks.includes(park[0]?.value)) {
            parks.push(park[0]?.value)
        }
    })

    return parks
}


/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useMoralis, useNFTBalances, useMoralisQuery } from 'react-moralis'
import { getTraits, handleAchievement } from '../utils/handleAchievement'




export default function useGetProgress(profile, achievements) {

	const { enqueueSnackbar } = useSnackbar();

	const [fetchingProgress, setFetchingProgress] = useState(false)


	const { Moralis } = useMoralis()
	const { getNFTBalances, data } = useNFTBalances()

	const navigate = useNavigate()

	const handleProgress = async () => {

		enqueueSnackbar('Syncing achievements...', { variant: 'warning' });

		const timer = ms => new Promise(res => setTimeout(res, ms))

		setFetchingProgress(true)
		const nfts = await getNFTBalances()

		const id = profile?.id
		const parks = nfts?.result?.filter((nft) => nft.symbol === "NPNFT").length
		const natParks = nfts?.result?.filter((nft) => nft.symbol === "NPNFT")
		const traits = await getTraits(natParks, Moralis)

		for await (const level of achievements) {

			switch (true) {
				case (parks >= level?.attributes?.weight && level?.attributes?.weight !== null):
					await handleAchievement(Moralis, level, id)
					break;
				case (level?.attributes?.traits?.every(i => traits?.includes(i))):
					await handleAchievement(Moralis, level, id)
					break;
				default:

			}

		}

		setFetchingProgress(false)
		enqueueSnackbar('Your achievements have been synced! ');




	}


	return { fetchingProgress, handleProgress: () => handleProgress() }
}
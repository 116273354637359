import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import { userSlice } from './slices/userSlice';


// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['user'],
};



const rootReducer = combineReducers({
    user: userSlice.reducer
});

export { rootPersistConfig, rootReducer };
// hooks
import { useMoralisQuery } from 'react-moralis';

// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ profile, ...other }) {


    return (
        <Avatar
            src={profile[0]?.attributes?.profileImage?._url}
            alt={profile[0]?.attributes?.displayName}
            color={profile[0]?.attributes?.photoURL ? 'default' : createAvatar(profile[0]?.attributes?.displayName).color}
            {...other}
        >
            {createAvatar(profile[0]?.attributes?.nickName).name}
        </Avatar>


    );
}

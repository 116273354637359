
import React from 'react'
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMoralis } from 'react-moralis';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from './hook-form';



export default function ClaimModal({ isOpen, handleClose, handleOpen, profile }) {

    const { Moralis } = useMoralis();

    const { enqueueSnackbar } = useSnackbar();

    const UpdateUserSchema = Yup.object().shape({
        claimCode: Yup.string().required('Claim code is required'),
    });

    const defaultValues = {
        claimCode: '',
    };

    const methods = useForm({
        resolver: yupResolver(UpdateUserSchema),
        defaultValues
    });

    const {
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        const Achievements = Moralis.Object.extend("Achievements")
        const query = new Moralis.Query(Achievements)

        const Achievement = Moralis.Object.extend("EarnedAchievements")
        const earnedAchievement = new Moralis.Query(Achievement)

        const currentPoints = profile?.attributes?.ProfilePoints
        const currentActivePoints = profile?.attributes?.activePoints

        query.equalTo("claimCode", data?.claimCode)

        const _achievement = await query.find()

        if (_achievement.length === 0) return enqueueSnackbar('No achievements found for this code!!', { variant: 'error' });
        if (_achievement[0]?.attributes?.claimDeadline < new Date()) return enqueueSnackbar('Achievement has expired!', { variant: 'error' });
        if (_achievement[0]?.attributes?.claimLimit <= _achievement[0]?.attributes?.claimed) return enqueueSnackbar('Claim limit has been reached!', { variant: 'error' })

        earnedAchievement.equalTo("profile", profile?.id)
        earnedAchievement.equalTo("achievementId", _achievement[0]?.id)

        const achievement = await earnedAchievement.find()

        if (achievement?.length !== 0) return console.log('Achievement Exists')

        if (_achievement[0]?.attributes?.claimed === undefined) {
            _achievement[0]?.set("claimed", 1)
            await _achievement[0]?.save()
        } else {
            _achievement[0]?.increment("claimed")
            await _achievement[0]?.save()
        }

        const newAchievement = new Achievement()

        await newAchievement.save({
            profile: profile?.id,
            achievementId: _achievement[0]?.id,
            name: _achievement[0]?.attributes?.rewardTitle,
            description: _achievement[0]?.attributes?.rewardDescription,
            achievementImage: _achievement[0]?.attributes?.achievementImage,
            points: Number(_achievement[0]?.attributes?.rewardPoints),
            category: _achievement[0]?.attributes?.rewardType,
            claimed: true,
        })



        profile.set("ProfilePoints", currentPoints + Number(_achievement[0]?.attributes?.rewardPoints))
        profile.set("activePoints", currentActivePoints + Number(_achievement[0]?.attributes?.rewardPoints))

        await profile.save()

        window.location.reload(false)

    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">
                <p style={{ fontSize: 32 }}> Have a claim code?</p>
                <p style={{ fontWeight: '300' }}>  If you attended one of our events, enter the claim code below and we'll top you off with some fresh ParkPoints!</p>
            </DialogTitle>
            <DialogContent>

                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid sx={{ mt: 3 }} item xs={12} md={12}>

                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 3,
                                columnGap: 2,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                            }}
                        >
                            <RHFTextField name="claimCode" label="Claim Code" />
                            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                                Confirm
                            </LoadingButton>
                        </Box>

                    </Grid>
                </FormProvider>

            </DialogContent>

        </Dialog>
    )
}






